import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useMutation, useQuery } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import clsx from 'clsx';
import {
    Alert,
    GenericError,
    Layout,
    NoSimDataFound,
    PrimaryButton,
    Table,
    TableHeader,
    TTableColumn,
    WhiteButton,
} from '../..';
import { activateSim, listAvailableSims } from '../../../api';
import {
    TAvailableSim,
    TApiListResponse,
    TSuccessApiListResponse,
    TSuccessApiInstanceResponse,
    TModifiedSim,
} from '../../../types';
import {
    checkRequiredParams,
    formatPaginationParams,
    isErrorResponse,
} from '../../../utils';
import { useApp, useUser } from '../../../contexts';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { simTypes } from 'src/constants';
const headerWithTitle = (title: string) => (props: unknown) => (
    <TableHeader title={title} {...props} />
);

type propType = { simType: string };

export const AvailableSims = ({ simType }: propType): ReactElement => {
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize] = useState(10);
    const [activatingSimIccid, setActivatingSimIccid] = useState<string>('');
    const [shouldUpdate, _shouldUpdate] = useState(false);
    const router = useRouter();
    const { spid } = router.query;
    const { auth } = useUser();
    const { activatedSims, setActivatedSims } = useApp();
    const activateCancelButtonRef = useRef(null);
    const { addToast } = useToasts();

    const simTitle = simType == simTypes.eSims ? 'eSIM' : 'SIM';
    const heading = `Available ${simTitle}s`;
    const pageTitle = `Available ${simTitle}s : ApaloSIM`;
    const [selectSimType, setselectSimType] = useState('sip');
    const listAvailableSimsQuery = useQuery<
        TApiListResponse<TAvailableSim>,
        string[]
    >(
        ['availableSims', { spid }, pageIndex],
        async () => {
            checkRequiredParams(spid);
            let params: Record<
                string,
                string | number
            > = formatPaginationParams({
                pageIndex,
                pageSize,
            });

            params = {
                ...params,
                type_of_sim: simType === simTypes.eSims ? 2 : 1,
            };

            const res = await listAvailableSims(
                auth.header,
                spid as string,
                simType,
                params,
            );

            if (isErrorResponse(res)) {
                throw res.errors;
            }

            return res;
        },
        { keepPreviousData: true },
    );

    useEffect(() => {
        if (shouldUpdate) {
            listAvailableSimsQuery.refetch();
        }
    }, [shouldUpdate, listAvailableSimsQuery.refetch]);

    const activateSimMutation = useMutation<
        TSuccessApiInstanceResponse<TModifiedSim>,
        string[],
        string
    >(async iccid => {
        const res = await activateSim(
            auth.header,
            spid as string,
            iccid,
            simType,
            selectSimType,
        );

        if (isErrorResponse(res)) {
            throw res.errors;
        }

        return res;
    });

    const { data: availableSimsResponse } = listAvailableSimsQuery;

    let errors: string[] = [];

    if (listAvailableSimsQuery.isError) {
        errors = [...errors, ...listAvailableSimsQuery.error];
    }

    if (errors.length > 0) {
        return (
            <Layout pageTitle={pageTitle} heading={heading}>
                <GenericError errors={errors} />
            </Layout>
        );
    }

    if (!availableSimsResponse) {
        return <Layout pageTitle={pageTitle} heading={heading} isLoading />;
    }

    if (!availableSimsResponse.success) {
        return (
            <Layout pageTitle={pageTitle} heading={heading}>
                <GenericError />
            </Layout>
        );
    }

    const {
        data: tableData,
        count: itemCount,
    } = availableSimsResponse as TSuccessApiListResponse<TAvailableSim>;

    const isActivating = activateSimMutation.isLoading;

    if (itemCount === 0) {
        return (
            <NoSimDataFound
                spid={spid as string}
                pageTitle={pageTitle}
                heading={heading}
                simTitle={simTitle}
                simType={simType}
                status="available"
            />
        );
    }

    const tableColumns: TTableColumn<TAvailableSim>[] = [
        {
            Header: headerWithTitle('ICCID'),
            accessor: 'iccid',
            className: 'w-4/12',
        },
        {
            Header: headerWithTitle('MSISDN'),
            accessor: 'msisdn',
            className: 'w-6/12',
        },
        {
            Header: (props: unknown) => (
                <TableHeader title="Activate" hidden {...props} />
            ),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            accessor: 'activateButton',
            className: 'w-1/12',
            disableSortBy: true,
            Cell: ({
                row,
            }: {
                row: {
                    values: { iccid: string; qr_code: string };
                    original: TAvailableSim;
                };
            }) => {
                const { iccid } = row.values;
                const isActivatingSim = activatedSims.includes(iccid);
                return (
                    <button
                        onClick={() => {
                            setActivatingSimIccid(iccid);
                        }}
                        className={clsx(
                            isActivating || isActivatingSim
                                ? 'cursor-not-allowed text-gray-400'
                                : 'text-primary-600 hover:text-primary-900',
                        )}
                        disabled={isActivatingSim}
                    >
                        {isActivatingSim ? 'Activating' : 'Activate'}
                    </button>
                );
            },
        },
    ];
    const simTextType = simType == simTypes.eSims ? 'eSIM' : 'SIM';

    const type = tableData.find(sim => sim.iccid === activatingSimIccid)
        ?.available_type;

    return (
        <>
            <Alert
                heading={`Activate ${simTextType}?`}
                onClose={() => {
                    setActivatingSimIccid('');
                }}
                show={Boolean(activatingSimIccid)}
                initialFocus={activateCancelButtonRef}
                Buttons={() => (
                    <div className="flex justify-end">
                        <WhiteButton
                            className="mr-4"
                            onClick={() => {
                                setActivatingSimIccid('');
                            }}
                            ref={activateCancelButtonRef}
                        >
                            Cancel
                        </WhiteButton>
                        <PrimaryButton
                            onClick={() => {
                                activateSimMutation.mutate(activatingSimIccid, {
                                    onSettled: () => {
                                        setActivatingSimIccid('');
                                    },
                                    onSuccess: () => {
                                        _shouldUpdate(prev => !prev);
                                        setActivatedSims(sims => [
                                            ...sims,
                                            activatingSimIccid,
                                        ]);
                                        addToast(null, {
                                            heading: `The ${simTextType} activation request has been sent successfully.`,
                                            appearance: 'success',
                                        });
                                    },
                                    onError: () => {
                                        addToast(null, {
                                            heading: 'Error Activating SIM',
                                            body:
                                                'Please try again or raise a support ticket if this continues.',
                                            appearance: 'error',
                                            autoDismiss: false,
                                        });
                                    },
                                });
                            }}
                            disabled={isActivating}
                        >
                            {isActivating ? 'Activating' : 'Activate'}
                        </PrimaryButton>
                    </div>
                )}
                Icon={() => (
                    <ExclamationCircleIcon
                        className="h-6 w-6 text-primary-600"
                        aria-hidden="true"
                    />
                )}
                iconBackgroundClassName="bg-primary-100"
            >
                <p className="text-sm text-gray-500">
                    Are you sure you want to activate the following SIM?
                </p>
                <dl className="mt-5">
                    <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 md:py-3 lg:py-2">
                        <dt className="text-sm font-medium text-gray-500 col-span-1 md:col-span-3 lg:col-span-1">
                            ICCID
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {activatingSimIccid}
                        </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 md:py-3 lg:py-2">
                        <dt className="text-sm font-medium text-gray-500 col-span-1 md:col-span-3 lg:col-span-1">
                            MSISDN
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {
                                tableData.find(
                                    sim => sim.iccid === activatingSimIccid,
                                )?.msisdn
                            }
                        </dd>
                    </div>
                    {Array.isArray(type) && !type?.includes('sip') ? (
                        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 md:py-3 lg:py-2">
                            <dt className="text-sm font-medium text-gray-500 col-span-1 md:col-span-3 lg:col-span-1">
                                Type
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <select
                                    className="block w-full text-sm text-gray-900 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                                    value={selectSimType}
                                    onChange={e =>
                                        setselectSimType(e.target.value)
                                    } // Update the ICCID state
                                >
                                    {['sip', 'mno', 'fmc'].map(
                                        (text, index) => (
                                            <option key={index} value={text}>
                                                {text.toUpperCase()}
                                            </option>
                                        ),
                                    )}
                                </select>
                            </dd>
                        </div>
                    ) : null}
                </dl>
            </Alert>
            <Layout pageTitle={pageTitle} heading={heading}>
                <Table<TAvailableSim>
                    data={tableData}
                    columns={tableColumns}
                    initialState={{
                        sortBy: [{ id: 'iccid', desc: false }],
                        pageIndex,
                    }}
                    itemCount={itemCount}
                    pageSize={pageSize}
                    onPaginate={setPageIndex}
                />
            </Layout>
        </>
    );
};
