import {
    TAllocatedSim,
    TAllocatedSimConfig,
    TAllocatedSimDetail,
    TAllocatedSimParams,
    TApiInstanceResponse,
    TApiListResponse,
    TErrorApiResponse,
    THttpMethod,
    TLogin,
    TQueryParamsObj,
    TSimRegistrationConfig,
    TSimRegistrationStatus,
    TApiObjectResponse,
    TEntitlements,
    TModifiedSim,
    TPendingOperation,
    TChangePasswordApiResponse,
    TSuspendedSim,
    TResetPassword,
    TSearchResult,
    TUnsuspendedSim,
    TSimInboundConfig,
} from '../types';

const genericErrorResponse: TErrorApiResponse = {
    status: 400,
    success: false,
    errors: [],
};

const fetchApi = async <T>(
    method: THttpMethod,
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body?: any,
    auth?: string | null,
    credentials?: RequestCredentials,
): Promise<{ res: Response | null; json?: T | TErrorApiResponse }> => {
    let res: Response;

    const headers: Record<string, string> = {
        'Content-Type': 'application/json',
    };

    if (auth) {
        headers['Authorization'] = auth;
    }

    try {
        res = await fetch(url, {
            method,
            headers,
            credentials,
            body: body ? JSON.stringify(body) : undefined,
        });
    } catch (err) {
        console.error('Error fetching API', err);
        return { res: null, json: genericErrorResponse };
    }

    if (res.status === 204) {
        return { res };
    }

    try {
        const json = await res.json();
        return { res, json };
    } catch (err) {
        console.error('Error parsing JSON');
        return { res, json: genericErrorResponse };
    }
};

const buildQueryString = (paramsObj: TQueryParamsObj = {}): string =>
    Object.entries(paramsObj).reduce((a, [key, value], i) => {
        const leading = i === 0 ? '?' : '&';
        return `${a}${leading}${key}=${value}`;
    }, '');

const apiBase = process.env.NEXT_PUBLIC_API_URL; // 'https://api.apalo.io';
const authBase = `${apiBase}/auth`;
const v1Base = `${apiBase}/v1`;

const buildUrl = (segments: string[], params?: TQueryParamsObj): string => {
    const queryString = buildQueryString(params);
    const url = [...segments].join('/').replace(/\/$/, '');
    return `${url}${queryString}`;
};

export const login = async (
    user: string,
    pass: string,
): Promise<TApiObjectResponse<TLogin>> => {
    const { json } = await fetchApi<TApiObjectResponse<TLogin>>(
        'POST',
        buildUrl([authBase, 'login']),
        {
            user,
            pass,
        },
        null,
        'include',
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const logOut = async (auth: string): Promise<TApiObjectResponse> => {
    const { json } = await fetchApi<TApiObjectResponse>(
        'POST',
        buildUrl([authBase, 'logout']),
        null,
        auth,
        'include',
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const resetPassword = async (
    user: string,
): Promise<TApiObjectResponse<TResetPassword>> => {
    const { json } = await fetchApi<TApiObjectResponse<TResetPassword>>(
        'POST',
        buildUrl([authBase, 'password', 'reset']),
        {
            user,
        },
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const listEntitlements = async (
    auth: string,
): Promise<TApiObjectResponse<TEntitlements>> => {
    const { json } = await fetchApi<TApiObjectResponse<TEntitlements>>(
        'GET',
        buildUrl([authBase, 'entitlements']),
        null,
        auth,
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const refreshToken = async (): Promise<TApiObjectResponse<TLogin>> => {
    const { json } = await fetchApi<TApiObjectResponse<TLogin>>(
        'GET',
        buildUrl([authBase, 'refresh_token']),
        null,
        null,
        'include',
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const changePassword = async (
    auth: string,
    oldPassword: string,
    newPassword: string,
): Promise<TChangePasswordApiResponse> => {
    const { json } = await fetchApi<TChangePasswordApiResponse>(
        'POST',
        buildUrl([authBase, 'password', 'change']),
        {
            old_password: oldPassword,
            new_password: newPassword,
        },
        auth,
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const changePasswordWithToken = async (
    token: string,
    newPassword: string,
): Promise<TChangePasswordApiResponse> => {
    const { json } = await fetchApi<TChangePasswordApiResponse>(
        'POST',
        buildUrl([authBase, 'password', 'change']),
        {
            reset_token: token,
            new_password: newPassword,
        },
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const listAllocatedSims = async (
    auth: string,
    spid: string,
    simType: string,
    params?: TAllocatedSimParams,
): Promise<TApiListResponse<TAllocatedSim>> => {
    const { json } = await fetchApi<TApiListResponse<TAllocatedSim>>(
        'GET',
        buildUrl([v1Base, spid, simType, 'allocated'], params),
        null,
        auth,
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const getAllocatedSimDetail = async (
    auth: string,
    spid: string,
    iccid: string,
    simType: string,
): Promise<TApiInstanceResponse<TAllocatedSimDetail>> => {
    const { json } = await fetchApi<TApiInstanceResponse<TAllocatedSimDetail>>(
        'GET',
        buildUrl([v1Base, spid, simType, 'allocated', iccid]),
        null,
        auth,
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const getAllocatedSimConfig = async (
    auth: string,
    spid: string,
    iccid: string,
    simType: string,
): Promise<TApiInstanceResponse<TAllocatedSimConfig>> => {
    const { json } = await fetchApi<TApiInstanceResponse<TAllocatedSimConfig>>(
        'GET',
        buildUrl([v1Base, spid, simType, 'allocated', iccid, 'config']),
        null,
        auth,
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const getSimRegistrationStatus = async (
    auth: string,
    spid: string,
    iccid: string,
    simType: string,
): Promise<TApiInstanceResponse<TSimRegistrationStatus>> => {
    const { json } = await fetchApi<
        TApiInstanceResponse<TSimRegistrationStatus>
    >(
        'GET',
        buildUrl([v1Base, spid, simType, 'allocated', iccid, 'sip', 'status']),
        null,
        auth,
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const getSimRegistrationConfig = async (
    auth: string,
    spid: string,
    iccid: string,
    simType: string,
): Promise<TApiInstanceResponse<TSimRegistrationConfig>> => {
    const { json } = await fetchApi<
        TApiInstanceResponse<TSimRegistrationConfig>
    >(
        'GET',
        buildUrl([v1Base, spid, simType, 'allocated', iccid, 'sip', 'config']),
        null,
        auth,
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const getSimInboundConfig = async (
    auth: string,
    spid: string,
    iccid: string,
    simType: string,
): Promise<TApiInstanceResponse<TSimInboundConfig>> => {
    const { json } = await fetchApi<TApiInstanceResponse<TSimInboundConfig>>(
        'GET',
        buildUrl([
            v1Base,
            spid,
            simType,
            'allocated',
            iccid,
            'inbound',
            'config',
        ]),
        null,
        auth,
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const listAvailableSims = async (
    auth: string,
    spid: string,
    simType: string,
    params?: TAllocatedSimParams,
): Promise<TApiListResponse<TAllocatedSim>> => {
    const { json } = await fetchApi<TApiListResponse<TAllocatedSim>>(
        'GET',
        buildUrl([v1Base, spid, simType, 'available'], {
            ...params,
        }),
        null,
        auth,
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const updateAllocatedSimDetail = async (
    auth: string,
    spid: string,
    iccid: string,
    changes: Partial<TAllocatedSimDetail>,
    simType: string,
): Promise<TApiInstanceResponse<TAllocatedSimDetail>> => {
    const { json } = await fetchApi<TApiInstanceResponse<TAllocatedSimDetail>>(
        'POST',
        buildUrl([v1Base, spid, simType, 'allocated', iccid]),
        changes,
        auth,
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const updateAllocatedSimConfig = async (
    auth: string,
    spid: string,
    iccid: string,
    changes: Partial<TAllocatedSimConfig>,
    simType: string,
): Promise<TApiInstanceResponse<TAllocatedSimConfig>> => {
    const { json } = await fetchApi<TApiInstanceResponse<TAllocatedSimConfig>>(
        'POST',
        buildUrl([v1Base, spid, simType, 'allocated', iccid, 'config']),
        changes,
        auth,
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const updateSimRegistrationConfig = async (
    auth: string,
    spid: string,
    iccid: string,
    changes: Partial<TSimRegistrationConfig>,
    simType: string,
): Promise<TApiInstanceResponse<TSimRegistrationConfig>> => {
    const { json } = await fetchApi<
        TApiInstanceResponse<TSimRegistrationConfig>
    >(
        'POST',
        buildUrl([v1Base, spid, simType, 'allocated', iccid, 'sip', 'config']),
        changes,
        auth,
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const updateSimInboundConfig = async (
    auth: string,
    spid: string,
    iccid: string,
    changes: Partial<TSimInboundConfig>,
    simType: string,
): Promise<TApiInstanceResponse<TSimInboundConfig>> => {
    const { json } = await fetchApi<TApiInstanceResponse<TSimInboundConfig>>(
        'POST',
        buildUrl([
            v1Base,
            spid,
            simType,
            'allocated',
            iccid,
            'inbound',
            'config',
        ]),
        changes,
        auth,
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const suspendSim = async (
    auth: string,
    spid: string,
    iccid: string,
    simType: string,
): Promise<TApiInstanceResponse<TSuspendedSim>> => {
    const { json } = await fetchApi<TApiInstanceResponse<TSuspendedSim>>(
        'POST',
        buildUrl([v1Base, spid, simType, 'allocated', iccid, 'suspend']),
        null,
        auth,
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const unsuspendSim = async (
    auth: string,
    spid: string,
    iccid: string,
    simType: string,
): Promise<TApiInstanceResponse<TUnsuspendedSim>> => {
    const { json } = await fetchApi<TApiInstanceResponse<TUnsuspendedSim>>(
        'POST',
        buildUrl([v1Base, spid, simType, 'allocated', iccid, 'unsuspend']),
        null,
        auth,
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const terminateSim = async (
    auth: string,
    spid: string,
    iccid: string,
    simType: string,
): Promise<TApiInstanceResponse<TModifiedSim>> => {
    const { json } = await fetchApi<TApiInstanceResponse<TModifiedSim>>(
        'POST',
        buildUrl([v1Base, spid, simType, 'allocated', iccid, 'terminate']),
        null,
        auth,
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const activateSim = async (
    auth: string,
    spid: string,
    iccid: string,
    simType: string,
    selectSimType: string,
): Promise<TApiInstanceResponse<TModifiedSim>> => {
    const { json } = await fetchApi<TApiInstanceResponse<TModifiedSim>>(
        'POST',
        buildUrl([v1Base, spid, simType, 'available', iccid, 'activate']),
        {
            type: selectSimType,
        },
        auth,
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const listPendingOperations = async (
    auth: string,
    spid: string,
    simType: string,
    params?: TAllocatedSimParams,
): Promise<TApiListResponse<TPendingOperation>> => {
    const { json } = await fetchApi<TApiListResponse<TPendingOperation>>(
        'GET',
        buildUrl([v1Base, spid, simType, 'pending'], params),
        null,
        auth,
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};

export const searchSims = async (
    auth: string,
    spid: string,
    query: string,
    sipOnly: boolean,
): Promise<{
    res: Response | null;
    json?: TApiListResponse<TSearchResult>;
}> => {
    const params: Record<string, string> = {
        q: query,
    };

    if (sipOnly) {
        params['filter_type'] = 'sip';
    }

    const { res, json } = await fetchApi<TApiListResponse<TSearchResult>>(
        'GET',
        buildUrl([v1Base, spid, 'sims', 'search'], params),
        null,
        auth,
    );

    return { res, json };
};

export const activateESim = async (
    auth: string,
    spid: string,
    iccid: string,
    simType: string,
): Promise<TApiInstanceResponse> => {
    const { json } = await fetchApi<TApiInstanceResponse>(
        'GET',
        buildUrl([v1Base, spid, simType, 'activate', iccid]),
        null,
        auth,
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return json!;
};
